import { getWeek, getYear } from 'date-fns';

/**
 * Model that's sent to the xOffice API
 */
export interface INewTimesheet {
    id?: number;
    client_id: number;
    department_id: number;
    shift_id: number;
    week: number;
    year: number;
    starts: string[];
    ends: string[];
    break_starts: string[];
    break_ends: string[];
    days: number[];
}

export class NewTimesheet implements INewTimesheet {
    id?: number | undefined;
    client_id: number;
    department_id: number;
    shift_id: number;
    week: number;
    year: number;
    starts: string[];
    ends: string[];
    break_starts: string[];
    break_ends: string[];
    days: number[];

    constructor(id?: number) {
        this.id = id;
        this.client_id = 0;
        this.department_id = 0;
        this.shift_id = 0;
        this.week = getWeek(new Date());
        this.year = getYear(new Date());
        this.starts = new Array<string>();
        this.ends = new Array<string>();
        this.break_starts = new Array<string>();
        this.break_ends = new Array<string>();
        this.days = new Array<number>();
    }
}

/**
 * Model that's received from the xOffice API
 */
export interface ISubmittedTimesheet {
    id: number;
    client: INameIdObject;
    department: INameIdObject;
    shift: INameIdObject;
    status: IStatus;
    hours: ITimeRegistration[];
    week?: number;
}

interface INameIdObject {
  name: string;
  id: number;
}

interface IStatus {
    name: string;
    class: string;
}

interface ITimeRegistration {
    start: string;
    end: string;
    break_start: string | null;
    break_end: string | null;
    hours: number;
}

