import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

/**
 * Checks whether two passwords are equal
 */
export const passwordEqualityValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const passwordControl = control.get('password');
  const passwordConfirmationControl = control.get('passwordConfirmation');

  return passwordControl && passwordConfirmationControl && passwordControl.value !== passwordConfirmationControl.value
    ? { passwordEquality: true} : null;
};
