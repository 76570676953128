import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ISubmittedTimesheet} from '@app/_models';
import {InAppNotificationService, SigType, TimesheetRepositoryService} from '@app/_services';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'overview-dialog',
    templateUrl: './overview-dialog.component.html',
    styleUrls: ['./overview-dialog.component.css'],
})
export class OverviewDialogComponent implements OnInit {
  public userSignature: string;
  public clientSignature: string;

  constructor(@Inject(MAT_DIALOG_DATA)
              public submittedTimesheet: ISubmittedTimesheet,
              private timesheetRepository: TimesheetRepositoryService,
              private notificationService: InAppNotificationService,
              private router: Router,
              private dialogRef: MatDialogRef<OverviewDialogComponent>)
    {

    }

    ngOnInit()
    {
      this.fetchTimesheetSignatures();
    }

    fetchTimesheetSignatures(): void
    {
      this.timesheetRepository.fetchTimesheetSignature(this.submittedTimesheet.id, SigType.USER)
        .subscribe(img => this.userSignature = img, (error: HttpErrorResponse) => {
          this.notificationService.fetchError('user signature', error.status);
        });
      this.timesheetRepository.fetchTimesheetSignature(this.submittedTimesheet.id, SigType.CLIENT)
        .subscribe(img => this.clientSignature = img, (error: HttpErrorResponse) => {
          this.notificationService.fetchError('client signature', error.status);
        });
    }

    get totalHours(): number {
      let result = 0;
      this.submittedTimesheet?.hours.forEach(val => {
        result += val?.hours;
      });
      return result;
    }

    editSelectedData(): void {
        this.router.navigate(['/timesheets/new'], {queryParams: {editing: true, id: this.submittedTimesheet.id}});
        this.dialogRef.close();
    }
  }
