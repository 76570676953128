import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

import {
  AuthenticationService,
  InAppNotificationService,
} from '@app/_services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public resetForm: FormGroup;

  public resettingPw = false;

  constructor(private authService: AuthenticationService,
              private fb: FormBuilder,
              private router: Router,
              private notificationService: InAppNotificationService) {
    this.loginForm = this.fb.group({
      email: ['', Validators.email],
      password: ['', Validators.required]
    });

    this.resetForm = this.fb.group({
      email: ['', Validators.email],
    });
  }

  /**
   * Use a dropshadow in screensizes below 500
   */
  get dropShadow(): boolean {
    return screen.width <= 500;
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated) {
      this.router.navigate(['overview']);
    }
  }

  /**
   * Submits the login form to the API
   */
  async onSubmit(): Promise<void> {
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.value.email, this.loginForm.value.password).subscribe(() => {
          this.authService.getUserProfile().subscribe(res => {
            if (res.password_changed_at === null) {
              this.router.navigate(['/change-password']);
            } else {
              this.router.navigate(['/overview']);
            }
          });
        },
        (err: any) => {
          this.notificationService.loginError(err.status);
        });
    }
  }

  /**
   * Sends a request to API to reset password
   */
  async onRequestResetPw(): Promise<void> {
    if (this.resetForm.valid){
      this.authService.requestPasswordReset(this.resetForm.value.email).subscribe(() => {
        this.notificationService.resetPwSuccess();
        this.resettingPw = false;
      }, (error: HttpErrorResponse) => {
        this.notificationService.resourceError(error.status);
      });
    }
  }
}
