<mat-vertical-stepper [linear]="true" #stepper class="content">
  <mat-step [stepControl]="generalInfoForm">
    <ng-template matStepLabel>{{'REGISTRATION.GENERALINFO' | translate | titlecase}}</ng-template>
    <form [formGroup]="generalInfoForm">
      <div *ngIf="isEditing" >
        <mat-form-field>
          <mat-label>{{'REGISTRATION.WEEK' | translate | titlecase}}</mat-label>
          <input matInput type="week" [disabled]="true" [value]="editingTimesheet.hours[0]?.start | date: 'yyyy\'-W\'ww' "/>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'REGISTRATION.CLIENT' | translate | titlecase}}</mat-label>
          <mat-select value="0" [disabled]="true">
            <mat-option value="0">{{editingTimesheet.client.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'REGISTRATION.DEPARTMENT' | translate | titlecase}}</mat-label>
          <mat-select value="0" [disabled]="true">
            <mat-option value="0">{{editingTimesheet.shift.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'REGISTRATION.SHIFT' | translate | titlecase}}</mat-label>
          <mat-select value="0" [disabled]="true">
            <mat-option value="0">{{editingTimesheet.shift.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="!isEditing">
      <mat-form-field>
        <mat-label>{{'REGISTRATION.WEEK' | translate | titlecase}}</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="weekYear" style="display: none" readonly/>
        <input matInput (click)="picker.open()" [value]="generalInfoForm.value.weekYear | date: 'yyyy\'-W\'ww'" readonly/>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker touchUi></mat-datepicker>
        <!--<input matInput type="date" formControlName="weekYear">-->
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{'REGISTRATION.CLIENT' | translate | titlecase}}</mat-label>
        <mat-select formControlName="client">
          <mat-option *ngFor="let client of availableClients | async" [value]="{id: client.id, name: client.name}">
            {{client.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{'REGISTRATION.DEPARTMENT' | translate | titlecase}}</mat-label>
        <mat-select formControlName="department">
          <mat-option *ngFor="let department of availableDepartments | async" [value]="{id: department.id, name: department.name}">
            {{department.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{'REGISTRATION.SHIFT' | translate | titlecase}}</mat-label>
        <mat-select formControlName="shift">
          <mat-option *ngFor="let shift of availableShifts | async" [value]="{id: shift.id, name: shift.name}">
            {{shift.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      </div>

      <div class="stepperButtons">
        <button mat-button matStepperNext [disabled]="generalInfoForm.invalid">{{'REGISTRATION.NEXT' | translate | titlecase}}</button>
      </div>
    </form>

  </mat-step>
  <mat-step [completed]="!timesForm?.invalid && amountOfTimeControls > 0">
    <ng-template matStepLabel>
      <div>{{'REGISTRATION.WORKEDHOURS' | translate | titlecase}}</div>
      <div *ngIf="generalInfoForm.value.weekYear">Week&nbsp;{{  generalInfoForm.value.weekYear | date: 'ww-yyyy' }}</div>
    </ng-template>
    <form [formGroup]="timesForm">
      <div formArrayName="days">
        <div *ngFor="let ctrl of dayFormArray.controls; let idx = index;" [formGroupName]="idx">
          <div style="padding: 20px 0px;">
            <div style="display: inline-block; width: 150px">
              <div>{{ctrl.value.date | date: 'EEEE' | titlecase}}</div>
              <div style="font-size: small;">{{ctrl.value.date | date: 'mediumDate'}}</div>
            </div>
            <button style="display: inline-block;" mat-icon-button
                    *ngIf="ctrl.controls.times.controls.length < 1"
                    (click)="addTimeControl('', '', '', '', ctrl.get('times'))">
              <mat-icon>add_circle</mat-icon>
            </button>
          </div>
          <div formArrayName="times" *ngIf="ctrl.get('times')?.value.length > 0">
            <div *ngFor="let timeControl of ctrl.controls.times.controls; let tIdx = index;"  [formGroupName]="tIdx">
              <mat-form-field class="timeInput" style="max-width: 75px;">
                <mat-label>{{'REGISTRATION.FROM' | translate | titlecase}}</mat-label>
                <input matInput type="time" formControlName="start">
              </mat-form-field>
              <mat-form-field class="timeInput" style="max-width: 75px;">
                <mat-label>{{'REGISTRATION.TILL' | translate | titlecase}} </mat-label>
                <mat-label *ngIf="compareTimeStrings(timeControl.value.start, timeControl.value.end) === 1">
                  ({{addDaysToDate(ctrl.value.date, 1) | date: 'EE'}})
                </mat-label>
                <input matInput type="time" formControlName="end">
              </mat-form-field>
              <button mat-icon-button (click)="removeTimeControl(tIdx, ctrl.controls.times)">
                <mat-icon>delete_outline</mat-icon>
              </button>
              <button style="display: inline-block;" mat-icon-button *ngIf="tIdx == 0" [disabled]="ctrl.controls.times.controls.length > 2" (click)="addTimeControl('', '', '', '', ctrl.get('times'))">
                <mat-icon>add_circle</mat-icon>
              </button>
              <div>
                <mat-form-field class="timeInput" style="max-width: 75px;">
                  <mat-label>{{'REGISTRATION.BREAK_FROM' | translate | titlecase}}</mat-label>
                  <input matInput type="time" formControlName="breakStart">
                </mat-form-field>
                <mat-form-field class="timeInput" style="max-width: 75px;">
                  <mat-label>{{'REGISTRATION.BREAK_TILL' | translate | titlecase}} </mat-label>
                  <mat-label *ngIf="compareTimeStrings(timeControl.value.breakStart, timeControl.value.breakEnd) === 1">
                    ({{addDaysToDate(ctrl.value.date, 1) | date: 'EE'}})
                  </mat-label>
                  <input matInput type="time" formControlName="breakEnd">
                </mat-form-field>
              </div>
              <div>
                <mat-error *ngIf="timeControl.errors && timeControl.errors.breakStart && timeControl.errors.breakEnd">
                  {{'REGISTRATION.BREAK_ERROR' | translate | titlecase}}
                </mat-error>
              </div>
            </div>
            <mat-error *ngIf="ctrl.get('times').hasError('overlapping')">
              {{'REGISTRATION.OVERLAPPING_ERROR' | translate | titlecase}}
            </mat-error>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
      <div class="stepperButtons">
        <button mat-button matStepperPrevious>{{'REGISTRATION.PREVIOUS' | translate | titlecase}}</button>
        <button mat-button matStepperNext [disabled]="timesForm.invalid || amountOfTimeControls <= 0" (click)="updateTotalMinutesInTimesForm()">{{'REGISTRATION.NEXT' | translate | titlecase}}</button>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>{{'REGISTRATION.USERSIGNATURE' | translate | titlecase}}</ng-template>
    <div style="border: 1px dashed black;">
      <canvas #signatureUser height="300"></canvas>
    </div>
    <div class="stepperButtons">
      <button mat-button style="margin-right: auto;"
              (click)="signaturePadUser.clear()">{{'REGISTRATION.CLEAR' | translate | titlecase}}</button>
      <button mat-button matStepperPrevious>{{'REGISTRATION.PREVIOUS' | translate | titlecase}}</button>
      <button mat-button matStepperNext>{{'REGISTRATION.NEXT' | translate | titlecase}}</button>
    </div>
  </mat-step>
  <mat-step>
    <form [formGroup]="clientValidationForm">
      <mat-expansion-panel style="box-shadow: none" expanded="true">
        <mat-expansion-panel-header>
          {{'REGISTRATION.GENERALINFO' | translate | titlecase}}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <table class="generalInfoTable" *ngIf="isEditing">
            <tr>
              <td><b>{{'REGISTRATION.WEEK' | translate | titlecase}}</b></td>
              <td>{{generalInfoForm.value.weekYear | date: 'w'}}</td>
            </tr>
            <tr>
              <td><b>{{'REGISTRATION.CLIENT' | translate | titlecase}}</b></td>
              <td>{{ editingTimesheet.client.name }}</td>
            </tr>
            <tr>
              <td><b>{{'REGISTRATION.DEPARTMENT' | translate | titlecase}}</b></td>
              <td>{{ editingTimesheet.department.name }}</td>
            </tr>
            <tr>
              <td><b>{{'REGISTRATION.SHIFT' | translate | titlecase}}</b></td>
              <td>{{ editingTimesheet.shift.name }}</td>
            </tr>
          </table>
          <table class="generalInfoTable" *ngIf="!isEditing">
            <tr>
              <td><b>{{'REGISTRATION.WEEK' | translate | titlecase}}</b></td>
              <td>{{generalInfoForm.value.weekYear | date: 'w'}}</td>
            </tr>
            <tr>
              <td><b>{{'REGISTRATION.CLIENT' | translate | titlecase}}</b></td>
              <td>{{generalInfoForm.value.client?.name}}</td>
            </tr>
            <tr>
              <td><b>{{'REGISTRATION.DEPARTMENT' | translate | titlecase}}</b></td>
              <td>{{generalInfoForm.value.department?.name}}</td>
            </tr>
            <tr>
              <td><b>{{'REGISTRATION.SHIFT' | translate | titlecase}}</b></td>
              <td>{{generalInfoForm.value.shift?.name}}</td>
            </tr>
          </table>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel style="box-shadow: none" expanded="true">
        <mat-expansion-panel-header>
          {{'OVERVIEW.TIMEREGISTRATIONS' | translate | titlecase}}
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <table id="hoursTable">
            <th colspan="2">{{'OVERVIEW.START' | translate | titlecase}}</th>
            <th colspan="2">{{'OVERVIEW.END' | translate | titlecase}}</th>
            <th colspan="1">{{'OVERVIEW.BREAK' | translate | titlecase}}</th>
            <th>{{'OVERVIEW.HOUR' | translate | titlecase}}</th>

            <ng-container *ngFor=" let row of timesForm.get('days').value">
              <tr *ngFor="let time of row.times">
                <td>{{row.date | date: 'E' | titlecase}}</td>
                <td>{{time.start}}</td>
                <td *ngIf="time.nextDay; else notNextDay">
                  {{addDaysToDate(row.date,1) | date: 'E' | titlecase }}
                </td>
                <ng-template #notNextDay>
                  <td>
                    {{row.date | date: 'E' | titlecase }}
                  </td>
                </ng-template>
                <td>{{time.end}}</td>
                <td>{{ time.breakStart }} - {{time.breakEnd}}</td>
                <td>
                    {{ calculateTotal({start: time.start, end: time.end, breakStart: time.breakStart, breakEnd: time.breakEnd}) }}
                </td>
              </tr>
            </ng-container>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style="border-top: 1px solid gray; padding-top: 8px;"><b>{{'REGISTRATION.TOTAL' | translate | titlecase}}</b><br/> {{totalHoursInTimesForm | async}}</td>
            </tr>
          </table>
        </ng-template>
      </mat-expansion-panel>
    <ng-template matStepLabel>
      <mat-label>{{'REGISTRATION.SIGNEDBY' | translate }}</mat-label>
    </ng-template>
      <div style="border: 1px dashed black;">
        <canvas #signatureClient height="400"></canvas>
      </div>
      <mat-form-field style="width: 100%; margin-top:20px; margin-bottom: 20px;">
        <mat-label>{{'REGISTRATION.SIGNEDBY' | translate }}</mat-label>
        <input matInput type="text" formControlName="clientName" required>
      </mat-form-field>
      <div class="stepperButtons">
        <button mat-button style="margin-right: auto;"
                (click)="signaturePadClient.clear()">{{'REGISTRATION.CLEAR' | translate | titlecase}}</button>
        <button mat-button matStepperPrevious>{{'REGISTRATION.PREVIOUS' | translate | titlecase}}</button>
        <button mat-button [disabled]="clientValidationForm.invalid || isSubmitting" (click)="onSubmit()">{{'REGISTRATION.CONFIRM' | translate | titlecase}}</button>
      </div>
    </form>
  </mat-step>
</mat-vertical-stepper>
