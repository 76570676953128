import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { format, getWeek } from 'date-fns';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { INewTimesheet, ISubmittedTimesheet, NewTimesheet } from '@app/_models';
import { InAppNotificationService } from '@app/_services/inAppNotification.service';
import { environment } from '@environments/environment';

interface SignatureObject {
  data: {
    signature: string;
  };
}

interface DataObject {
  data: {};
}

interface SaveTimesheetResultObject {
  timesheet_id: number;
  message: any;
}

/**
 * Signature type for signing timesheets
 */
export enum SigType {
  USER = 1,
  CLIENT = 2,
}

@Injectable({ providedIn: 'root' })
export class TimesheetRepositoryService {
  public submittedTimesheets = new BehaviorSubject<ISubmittedTimesheet[]>(
    new Array<ISubmittedTimesheet>()
  );

  /**
   * Returns a new instance of the timesheet repository
   */
  constructor(
    private httpClient: HttpClient,
    private notificationService: InAppNotificationService
  ) {}

  /**
   * Refresh the timesheets in the repository by fetching them from the API
   */
  public refreshSubmittedTimesheets(year: number, month: number): void {
    this.fetchSubmittedTimesheets(year, month).subscribe(
      (result) => {
        this.submittedTimesheets.next(result);
      },
      (error) => this.notificationService.fetchError('clients', error.status)
    );
  }

  /**
   * Transform an already submitted timesheet to a 'new' timesheet to edit it
   */
  public submittedTimesheetToNewTimesheet(
    timesheet: ISubmittedTimesheet
  ): INewTimesheet {
    const result = new NewTimesheet(timesheet.id);
    result.client_id = timesheet.client.id;
    result.department_id = timesheet.department.id;
    result.shift_id = timesheet.shift.id;

    timesheet.hours.forEach((registration) => {
      const startDate = new Date(registration.start);
      const endDate = new Date(registration.end);
      const breakStart = registration.break_start ? new Date(registration.break_start) : null;
      const breakEnd = registration.break_end ? new Date(registration.break_end): null;

      // Exception for sunday, because API logic starts on monday=1
      const day = startDate.getDay() === 0 ? 7 : startDate.getDay();
      result.days.push(day);

      result.starts.push(format(startDate, 'HH:mm'));
      result.ends.push(format(endDate, 'HH:mm'));

      if(breakStart && breakEnd) {
        result.break_starts.push(format(breakStart, 'HH:mm'));
        result.break_ends.push(format(breakEnd, 'HH:mm'));
      }

      result.week = getWeek(startDate, { firstWeekContainsDate: 4 });
      result.year = startDate.getFullYear();
    });

    return result;
  }

  /**
   * Sign a timesheet using an id
   * @param type Whether the user or the company provided a signature
   * @param image Base64 signature
   * @param name Name of person who signed the signature
   */
  public signTimesheet(
    timesheetId: number,
    type: SigType,
    image: string,
    name: string
  ): Observable<any> {
    return this.httpClient.post(
      `${environment.API_URL}/timesheets/${timesheetId}/signature`,
      { signature: image, type, name }
    );
  }

  /**
   * Add or update a timesheet depending on whether it already has an id
   * @param timesheet
   */
  public saveTimesheet(
    timesheet: INewTimesheet
  ): Observable<SaveTimesheetResultObject> {
    if (timesheet.id === undefined) {
      return this.httpClient
        .post<{ data: SaveTimesheetResultObject }>(
          `${environment.API_URL}/timesheets`,
          JSON.stringify(timesheet),
          { headers: { 'Content-Type': 'application/json' } }
        )
        .pipe(map((res) => res.data));
    }

    return this.httpClient
      .put<{ data: number }>(
        `${environment.API_URL}/timesheets/${timesheet.id}`,
        JSON.stringify(timesheet),
        { headers: { 'Content-Type': 'application/json' } }
      )
      .pipe(map((res) => ({ timesheet_id: res.data, message: '' })));
  }

  /**
   * Fetch a single timesheet from the API, used for viewing or transforming it into an editable timesheet
   */
  public fetchSingleSubmittedTimesheet(
    timesheetId: number
  ): Observable<ISubmittedTimesheet> {
    return this.httpClient
      .get<DataObject>(`${environment.API_URL}/timesheets/${timesheetId}`)
      .pipe(
        map((result) => {
          const res = result.data as ISubmittedTimesheet;
          res.hours.sort(this.dateSorter);
          return res;
        })
      );
  }

  /**
   * Function to sort the timesheets by date
   * @param a first date
   * @param b second date
   */
  public dateSorter(a: any, b: any): number {
    return new Date(a.start).getDay() - new Date(b.start).getDay();
  }

  /**
   * Fetch submitted timesheets for the overview
   */
  public fetchSubmittedTimesheets(
    year: number,
    month: number
  ): Observable<ISubmittedTimesheet[]> {
    return this.httpClient
      .get<DataObject>(
        `${environment.API_URL}/timesheets?year=${year}&month=${month}`
      )
      .pipe(
        map((res) => {
          const result = res.data as ISubmittedTimesheet[];
          // Sort days within the result sunday -> saturday
          result.forEach((value) => {
            value.hours.sort(this.dateSorter);
          });
          return result;
        })
      );
  }

  /**
   * Fetch a signature for a single timesheet
   * @param id ID of timesheet
   * @param signature Whether to fetch the user's signature or the company's
   */
  public fetchTimesheetSignature(
    id: number,
    signature: SigType
  ): Observable<string> {
    return this.httpClient
      .get<SignatureObject>(
        `${environment.API_URL}/timesheets/${id}/signature/${signature}`
      )
      .pipe(map((res) => res.data.signature.replace(/\\\//g, '/'))); // Unescape string
  }
}
