import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {TranslationService} from '@app/_services/translation.service';

@Injectable({providedIn: 'root'})
export class InAppNotificationService {
  public notification$: Subject<string> = new Subject<string>();

  constructor(private translationService: TranslationService) {

  }

  /**
   * Notify the user that an error has occurred
   */
  private notifyError(messageKey: string, errCode?: number): void {
    this.translationService.getTranslation(messageKey).subscribe(value => {
      this.notification$.next(`${value} Code: ${errCode}`);
    });
  }

  /**
   * Notify the user that something has happened
   */
  public notifyInfo(messageKey: string): void {
    this.translationService.getTranslation(messageKey).subscribe(value => {
      this.notification$.next(`${value}`);
    });
  }

  public saveError = (errCode?: number) => this.notifyError('MESSAGES.SAVINGERROR', errCode);
  public fetchError = (resource?: string, errCode?: number) => this.notifyError('MESSAGES.FETCHERROR', errCode);

  public loginError = (errCode?: number) => this.notifyError('MESSAGES.LOGINERROR', errCode);
  public authError = (errCode?: number) => this.notifyError('MESSAGES.AUTHERROR', errCode);
  public submitFormError = () => this.notifyInfo('MESSAGES.SUBMITERROR');

  public saveSuccess = () => this.notifyInfo('MESSAGES.SAVESUCCES');
  public loginSuccess = () => this.notifyInfo('MESSAGES.LOGINSUCCES');
  public logoutSuccess = () => this.notifyInfo('MESSAGES.LOGOUTSUCCES');
  public resetPwSuccess = () => this.notifyInfo('MESSAGES.PASSWORDRESET');
  public resourceError = (errCode?: number) => this.notifyInfo('MESSAGES.RESTERROR');
}
