<mat-card class="appMatCard mat-elevation-z2">
  <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
    <mat-card-header>
      <p>{{'ChangePassword.ChangePassword' | translate}}</p>
    </mat-card-header>
    <mat-card-content>
      <div style="text-align: center">
        <p style="text-align: left;">{{'ChangePassword.FirstTimeMessage' | translate}}</p>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>{{'ChangePassword.NewPassword' | translate}}</mat-label>
        <input matInput type="password" id="password" formControlName="password" required>
        <mat-error *ngIf="this.passwordForm.getError('minlength', 'password')">{{'ChangePassword.MinLength' | translate}}</mat-error>
        <mat-error *ngIf="this.passwordForm.getError('pattern', 'password')">{{'ChangePassword.InvalidTokens' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'ChangePassword.Confirmation' | translate}}</mat-label>
        <mat-hint *ngIf="passwordForm.hasError('passwordEquality')" style="color: red">{{'ChangePassword.NotEqual' | translate}}</mat-hint>
        <input matInput type="password" formControlName="passwordConfirmation" required>
      </mat-form-field>
      <div style="text-align: left">
        <mat-hint><b>{{'ChangePassword.PasswordStrength' | translate}}</b>
          <span [ngSwitch]="passwordStrengthScore" style="display: inline">
            <p *ngSwitchCase="0">{{'ChangePassword.NotStrong' | translate}}</p>
            <p *ngSwitchCase="1">{{'ChangePassword.Mediocre' | translate}}</p>
            <p *ngSwitchCase="2">{{'ChangePassword.Decent' | translate}}</p>
            <p *ngSwitchCase="3">{{'ChangePassword.Strong' | translate}}</p>
            <p *ngSwitchCase="4">{{'ChangePassword.VeryStrong' | translate}}</p>
          </span>
        </mat-hint>
        <mat-progress-bar #passwordStrengthBar mode="determinate" [value]="progressBarValue"></mat-progress-bar>
      </div>
      <br/>
    </mat-card-content>
    <mat-card-actions style="display: flex; justify-content: space-between">
      <button mat-flat-button color="primary" type="submit">{{'ChangePassword.Confirm' | translate}}</button>
      <button mat-flat-button color="secondary" type="submit" (click)="onCancel()">{{'ChangePassword.Cancel' | translate}}</button>
    </mat-card-actions>
  </form>
</mat-card>
