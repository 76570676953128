import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localeEn from '@angular/common/locales/en';
import {registerLocaleData} from '@angular/common';

const defaultLanguage = 'nl';
const availableLanguages = ['en', 'nl', 'pl'];

@Injectable({providedIn: 'root'})
export class TranslationService {
  public CurrentLanguage: BehaviorSubject<string> = new BehaviorSubject<string>(localStorage.getItem('lang') ?? defaultLanguage);

  /**
   * Returns a new instance of the translation service
   */
  constructor(private translateService: TranslateService) {
    translateService.addLangs(this.availableLangs);
    translateService.setDefaultLang(this.CurrentLanguage.getValue());

    this.CurrentLanguage.subscribe(val => {
      if (this.matchesAvailableLangs(val)) {
        localStorage.setItem('lang', val);
        // Update translation service (custom values like buttons and messages)
        this.translateService.use(val);
        // Register locale with angular (app generated values like days and months)
        this.registerLocale(val);
      } else {
        this.CurrentLanguage.next(defaultLanguage);
      }
    });
  }

  /**
   * Get a translation for a specific string
   * Example: Overview.LogoutButton
   * @param key
   */
  getTranslation(key: string): Observable<string> {
    return this.translateService.get(key);
  }

  /**
   * Available languages in application
   * @private
   */
  get availableLangs(): string[] {
    return availableLanguages;
  }

  /**
   * Gets the browser's default language
   */
  get browserLang(): string {
    return this.translateService.getBrowserLang();
  }

  /**
   * Check if a matches an available language in the application
   * @param str (ISO 639-2)
   */
  matchesAvailableLangs(str: string): boolean {
    return this.availableLangs.some(e => e.match(str));
  }

  /**
   * Registers a locale with Angular, used when changing the application language
   * @param locale (ISO 639-2)
   * @private
   */
  private registerLocale(loc: string): void {
    if (loc === 'nl') {
      registerLocaleData(localeNl);
    } else if (loc === 'pl') {
      registerLocaleData(localePl);
    } else if (loc === 'en') {
      registerLocaleData(localeEn);
    }
  }
}
