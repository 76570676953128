<div class="content">
    <div style="text-align: center;">
        <button mat-icon-button (click)="addMonthToViewingDate(-1)" on>
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
      <div style="display: inline-block; width:150px">
            <span>{{currentDate | async | date: "LLLL yyyy" | titlecase }}</span>
        </div>
      <button mat-icon-button (click)="resetViewingDate()">
        <mat-icon>today</mat-icon>
      </button>
        <button mat-icon-button (click)="addMonthToViewingDate(1)">
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
    </div>
    <table id="overviewTable" mat-table [dataSource]="dataSource" matSort>

        <!--- Note that this only defines the columns and not the order -->
        <!--- See columnsToDisplay on the component -->

        <!-- Position Column -->
        <ng-container matColumnDef="week">
            <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc"><b> {{'OVERVIEW.WEEK' | translate | titlecase}} </b></th>
            <td mat-cell *matCellDef="let element"> {{element.hours[0]?.start | date: 'w'}} </td>
        </ng-container>

        <ng-container matColumnDef="client">
            <th mat-header-cell *matHeaderCellDef><b> {{'OVERVIEW.CLIENT' | translate | titlecase}} </b></th>
            <td mat-cell *matCellDef="let element"> {{element.client.name}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="department">
            <th mat-header-cell *matHeaderCellDef><b> {{'OVERVIEW.DEPARTMENT' | translate | titlecase}} </b></th>
            <td mat-cell *matCellDef="let element"> {{element.department.name}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="shift">
            <th mat-header-cell *matHeaderCellDef><b> {{'OVERVIEW.SHIFT' | translate | titlecase}} </b></th>
            <td mat-cell *matCellDef="let element"> {{element.shift.name}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef style="text-align: center"><b> {{'OVERVIEW.STATUS' | translate | titlecase}} </b></th>
            <td mat-cell *matCellDef="let element" style="text-align: center"> {{translateStatus(element.status.name)}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="hours">
          <th mat-header-cell *matHeaderCellDef style="text-align: center"><b> {{'OVERVIEW.HOURS' | translate | titlecase}} </b></th>
          <td mat-cell *matCellDef="let element" style="text-align: center;"> {{reduceHoursToTotal(element)}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="smallScreen ? columnsToDisplay : columnsToDisplayDesktop"></tr>
        <tr mat-row *matRowDef="let row; columns: smallScreen ? columnsToDisplay : columnsToDisplayDesktop;" (click)="openDialog(row)"></tr>
    </table>
</div>
