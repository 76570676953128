export class Client {
    id: number;
    name: string;
    departments: Department[];
}

export class Department {
    id: number;
    name: string;
    shifts: Shift[];
}

export class Shift {
    id: number;
    name: string;
}
