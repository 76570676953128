import {Component, OnInit } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService, InAppNotificationService, TranslationService} from '@app/_services';
import {Location} from '@angular/common';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css']
})
export class MainLayoutComponent implements OnInit {
  dataIsBeingMocked = false;

  /**
   * Returns a new instance of the App component
   */
  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private auth: AuthenticationService,
    private notificationService: InAppNotificationService,
    private translationService: TranslationService,
    private location: Location
  ) {
    this.dataIsBeingMocked = environment.MOCK_ENABLED;
    this.notificationService.notification$.subscribe(message => {
      this.snackBar.open(message, 'Oké', {duration: 5000});
    });
    this.openInfoSnackbar();
  }

  ngOnInit(): void {

  }
  get isChangingPassword(): boolean {
    return this.router.url.includes('change-password');
  }

  /**
   * Opens the informational snackbar when the user first uses the application
   */
  openInfoSnackbar(): void {
    this.location.onUrlChange(() => {
      if (!this.smallScreen && this.userAuthenticated && this.location.path().includes('/timesheets/overview') && this.getLocalStorageKey('firstUse') !== 'false') {
        this.translationService.getTranslation('TOOLBAR.tooltipCreate').subscribe(translation => {
          this.snackBar.open(translation,
            '',
            {duration: 8000, panelClass: 'snackbarPos', verticalPosition: 'top', horizontalPosition: 'start'});
        });
      }
    });
 }

  /**
   * Sets a local storage item with key/value
   * @param key
   * @param value
   */
  setLocalStorageKey(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  /**
   * Get a local storage item with using key
   * @param key
   */
  getLocalStorageKey(key: string): string | null {
    return localStorage.getItem(key);
  }

  /**
   * Returns an array of available languages from the translationservice
   */
  get availableLangs(): string[] {
    return this.translationService.availableLangs;
  }

  /**
   * Returns the language currently in use by the translationservice
   */
  get currentLang(): Observable<string> {
    return this.translationService.CurrentLanguage;
  }

  /**
   * Sets the language for the entire application
   * @param lang ISO 639-2 language code
   */
  setLanguage(lang: string): void {
    this.translationService.CurrentLanguage.next(lang);
    this.translationService.CurrentLanguage.subscribe(() => {
      location.reload();
    });
  }

  /**
   * Returns whether the current user is authenticated or not
   */
  get userAuthenticated(): boolean {
    return this.auth.isAuthenticated;
  }

  /**
   * Returns whether the user is on a small screen or not
   */
  get smallScreen(): boolean {
    return screen.width <= 500;
  }

  /**
   * Action to execute when logoff is clicked
   * Logout; Set userprofile to null; Navigate to login
   */
  onLogoffClick = () => {
    this.auth.logout();
    this.router.navigate(['/login'], {queryParams: { loggedOut: true}});
  }

  /**
   * Action to execute when the overview button is clicked
   */
  onOverviewClick(): void {
    this.router.navigate(['/timesheets/overview']);
  }

  /**
   * Action to execute when the create button is clicked
   */
  onCreateClick(): void {
    // Disable the informational snackbar in ngInit once the user knows the steps
    this.setLocalStorageKey('firstUse', 'false');
    this.router.navigate(['/timesheets/new']);
  }
}
