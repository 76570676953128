import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  Router,
  RouterModule,
} from '@angular/router';

import {
  ChangePasswordComponent,
} from '@app/_components/change-password/change-password.component';
import {
  DeclarationComponent,
} from '@app/_components/declaration/declaration.component';
import { LoginComponent } from '@app/_components/login/login.component';
import {
  OverviewDialogComponent,
} from '@app/_components/overview/overview-dialog.component';
import {
  OverviewComponent,
} from '@app/_components/overview/overview.component';
import {
  BasicAuthInterceptor,
  ErrorInterceptor,
} from '@app/_helpers';
import { TranslationService } from '@app/_services';
import { MaterialModule } from '@app/material/material.module';
import { environment } from '@environments/environment';
import {
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';

import {
  MainLayoutComponent,
} from './layout/main-layout/main-layout.component';

/**
 * AOT requires a factory function for HttpLoaders so it can load i18n files
 */
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

function initSentry() {
  if (environment.production) {
    return [
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: false,
        }),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      },
    ];
  }

  return [];
}

@NgModule({
  declarations: [
    MainLayoutComponent,
    LoginComponent,
    OverviewComponent,
    OverviewDialogComponent,
    DeclarationComponent,
    ChangePasswordComponent,
  ],
  exports: [MainLayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ...initSentry(),
    MatDatepickerModule,
    MatNativeDateModule,
    {
      provide: LOCALE_ID,
      deps: [TranslationService],
      useFactory: (translationService: TranslationService) =>
        translationService.CurrentLanguage.getValue(),
    },
  ],
})
export class CoreModule {}
