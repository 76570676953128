<mat-card [ngClass]="{'mat-elevation-z0': dropShadow, 'mat-elevation-z4': !dropShadow}">
    <mat-card-content>
        <img class="login-header-image" src="../../../assets/logo.png"/>
        <form (ngSubmit)="onSubmit()" [formGroup]="loginForm" [hidden]="resettingPw">
            <mat-form-field class="full-width-input" appearance="outline">
                <input matInput type="email" placeholder="{{ 'LOGIN.EMAIL' | translate | titlecase }}" formControlName="email" required>
                <mat-error>
                    {{ 'MESSAGES.INPUTINVALIDEMAIL' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-input" appearance="outline">
                <input matInput type="password" placeholder="{{ 'LOGIN.PASSWORD' | translate | titlecase }}" formControlName="password" required>
                <mat-error>
                  {{ 'MESSAGES.INPUTINVALIDPASSWORD' | translate }}
                </mat-error>
            </mat-form-field>
            <button mat-flat-button color="primary" class="login-button" type="submit">{{ 'LOGIN.LOGIN' | translate | titlecase}}</button>
        </form>
        <form (ngSubmit)="onRequestResetPw()" [formGroup]="resetForm" [hidden]="!resettingPw">
          <mat-form-field class="full-width-input" appearance="outline">
            <input matInput type="email" placeholder="{{ 'LOGIN.EMAIL' | translate | titlecase }}" formControlName="email" required>
            <mat-error>
              {{ 'MESSAGES.INPUTINVALIDEMAIL' | translate }}
            </mat-error>
          </mat-form-field>
          <button mat-flat-button color="primary" class="login-button" type="submit">{{ 'LOGIN.SEND' | translate | titlecase}}</button>
        </form>
      <button mat-flat-button style="margin-top: 20px; width: 100%" (click)="resettingPw = !resettingPw">
        <span [hidden]="resettingPw">{{ 'LOGIN.FORGOTPASSWORD' | translate}}</span>
        <span [hidden]="!resettingPw">{{ 'LOGIN.BACK' | translate | titlecase }}</span>
      </button>
    </mat-card-content>
</mat-card>
