import { formatDate } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  LOCALE_ID,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import {
  addDays,
  areIntervalsOverlapping,
  compareAsc,
  differenceInMinutes,
  format,
  isValid,
  isWithinInterval,
  parseISO,
  roundToNearestMinutes,
  setDay,
  startOfWeek,
} from 'date-fns';
import { BehaviorSubject } from 'rxjs';
import SignaturePad from 'signature_pad';

import {
  Client,
  Department,
  ISubmittedTimesheet,
  NewTimesheet,
  Shift,
} from '@app/_models';
import {
  ClientRepositoryService,
  InAppNotificationService,
  SigType,
  TimesheetRepositoryService,
} from '@app/_services';

enum TimeComparison {
  BEFORE = -1,
  EQUAL = 0,
  AFTER = 1,
}

@Component({
  selector: 'app-declaration',
  templateUrl: './declaration.component.html',
  styleUrls: ['./declaration.component.css'],
})
export class DeclarationComponent implements AfterViewInit {
  @ViewChild('signatureUser') signatureUserCanvas: ElementRef;
  @ViewChild('signatureClient') signatureClientCanvas: ElementRef;
  public signaturePadUser: SignaturePad;
  public signaturePadClient: SignaturePad;

  public availableClients: BehaviorSubject<Client[]> = new BehaviorSubject<
    Client[]
  >([]);
  public availableDepartments: BehaviorSubject<Department[]> =
    new BehaviorSubject<Department[]>([]);
  public availableShifts: BehaviorSubject<Shift[]> = new BehaviorSubject<
    Shift[]
  >([]);

  public totalMinutesInTimesForm: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  public totalHoursInTimesForm: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  public generalInfoForm: FormGroup;
  public timesForm: FormGroup;
  public amountOfTimeControls = 0;

  public clientValidationForm: FormGroup;
  public weekDatesArray: Array<Date>;

  public isEditing = false;
  public isSubmitting = false;
  public editingTimesheet: ISubmittedTimesheet;
  public workingTimesheet = new NewTimesheet();

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private notificationService: InAppNotificationService,
    private router: Router,
    private clientRepository: ClientRepositoryService,
    private timesheetRepository: TimesheetRepositoryService
  ) {
    this.availableClients = this.clientRepository.clients;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.weekDatesArray = this.getWeekInDates();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  async ngOnInit(): Promise<void> {
    this.generalInfoForm = this.createGeneralInfoForm();
    this.clientValidationForm = this.createClientValidationForm();
    this.timesForm = this.createTimesForm();

    /**
     * If the route contains any timesheet id, fetch it and enable editing mode
     */
    this.route.queryParams.subscribe((params) => {
      if (params.id !== undefined) {
        this.timesheetRepository
          .fetchSingleSubmittedTimesheet(params.id)
          .subscribe((ts) => {
            this.isEditing = true;
            this.generalInfoForm.disable();
            this.editingTimesheet = ts;
            this.workingTimesheet =
              this.timesheetRepository.submittedTimesheetToNewTimesheet(ts);
            if (!!ts.hours[0]?.start) {
              const weekYear = parseISO(ts.hours[0].start);

              this.generalInfoForm.get('weekYear')?.setValue(weekYear);
            }

            // Update worked hours with filled in values
            this.workingTimesheet.days.forEach((val, idx) => {
              const ctrl = this.dayFormArray.controls[val - 1];
              const start = this.workingTimesheet.starts[idx];
              const end = this.workingTimesheet.ends[idx];
              const breakStart = this.workingTimesheet.break_starts?.[idx] ?? '';
              const breakEnd = this.workingTimesheet.break_ends?.[idx] ?? '';
              this.addTimeControl(
                start,
                end,
                breakStart,
                breakEnd,
                ctrl.get('times')
              );
            });

            this.updateTotalMinutesInTimesForm();
          });
      } else {
        this.isEditing = false;
      }
    });

    if (!this.isEditing) {
      this.generalInfoForm
        .get('weekYear')
        ?.valueChanges.subscribe((nv: Date) => {
          if (this.timesForm?.enabled) {
            const dayCtrls = this.timesForm.get('days') as FormArray;
            dayCtrls.controls.forEach((element, index) => {
              element.setValue({
                ...element.value,
                date: setDay(nv, index + 1),
              });
            });
          }
        });
    }
  }

  ngAfterViewInit(): void {
    this.signaturePadUser = new SignaturePad(
      this.signatureUserCanvas.nativeElement
    );
    this.signaturePadClient = new SignaturePad(
      this.signatureClientCanvas.nativeElement
    );

    this.resizeCanvases();
  }

  get dayFormArray(): FormArray {
    return this.timesForm.get('days') as FormArray;
  }

  /**
   * Add a specific amount of days to a specific date
   */
  addDaysToDate(date: Date | string, days: number): Date {
    return addDays(new Date(date), days);
  }

  /**
   * Returns an array of dates for the current week
   */
  getWeekInDates(): Array<Date> {
    const result = [];
    const weekStart = startOfWeek(new Date(), { weekStartsOn: 1 });
    for (let i = 0; i < 7; i++) {
      result.push(addDays(weekStart, i));
    }
    return result;
  }

  /**
   * Resize the signature canvas to match screensizes
   */
  resizeCanvases(): void {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    this.signatureUserCanvas.nativeElement.width =
      this.signatureClientCanvas.nativeElement.width =
        this.signatureUserCanvas.nativeElement.offsetWidth * ratio;
    this.signatureUserCanvas.nativeElement.height =
      this.signatureClientCanvas.nativeElement.height =
        this.signatureUserCanvas.nativeElement.offsetHeight * ratio;
    this.signatureUserCanvas.nativeElement.getContext('2d').scale(ratio, ratio);
    this.signatureClientCanvas.nativeElement
      .getContext('2d')
      .scale(ratio, ratio);
    this.signaturePadUser.clear(); // otherwise isEmpty() might return incorrect value
    this.signaturePadClient.clear(); // otherwise isEmpty() might return incorrect value
  }

  /**
   * Compare two times by converting them to dates;
   * if t1 is after t2 return 1;
   * if t1 is before t2 return -1;
   * if t1 === t2 return 0;
   * @param t1 first timestring/date
   * @param t2 second timestring/date
   */
  compareTimeStrings(t1: string, t2: string): number {
    const dt1 = this.timeToDate(t1);
    const dt2 = this.timeToDate(t2);
    return compareAsc(dt1, dt2);
  }

  /**
   * Constructs the first form for submitting a timesheet
   */
  createGeneralInfoForm(): FormGroup {
    const group = this.fb.group({
      client: [null, [Validators.required]],
      department: [null, [Validators.required]],
      shift: [null, [Validators.required]],
      weekYear: [null, [Validators.required]],
    });

    group.get('client')?.valueChanges?.subscribe((value) => {
      if (group.enabled) {
        this.availableDepartments.next(
          this.clientRepository.getDepartments(value.id) || []
        );
        group.controls.department.patchValue(null);
      }
    });

    group.get('department')?.valueChanges?.subscribe((value) => {
      if (group.enabled) {
        this.availableShifts.next(
          this.clientRepository.getShifts(
            group.get('client')?.value.id,
            value?.id
          ) || []
        );
        group.controls.shift.patchValue(null);
      }
    });
    return group;
  }

  /**
   * Constructs a validation form for client names
   */
  createClientValidationForm(): FormGroup {
    const group = this.fb.group({
      clientName: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.pattern(/^[a-zA-Z\s]*$/),
        ],
      ],
    });

    return group;
  }

  /**
   * Constructs form for registering times on a timesheet
   */
  createTimesForm(): FormGroup {
    const group = this.fb.group({
      days: this.fb.array([]),
    });

    this.weekDatesArray.forEach((date) => {
      (group.controls.days as FormArray).push(this.createDateControl(date));
    });

    return group;
  }

  /**
   * Remove a time selector control
   */
  removeTimeControl(ctrlIndex: number, controlArray: FormArray): void {
    this.amountOfTimeControls--;
    controlArray.removeAt(ctrlIndex);
    this.updateTotalMinutesInTimesForm();
  }

  /**
   * Add a time control to a 'times' control (day control)
   * @param start default start time
   * @param end default end time
   * @param times control
   */
  addTimeControl(
    start: string,
    end: string,
    breakStart: string,
    breakEnd: string,
    times: any
  ): void {
    const timeValidationPattern = new RegExp('[0-9]{2}:(00|15|30|45)');
    const roundTimeStringToNearestQuarter = (time: string) => {
      const dateTime = new Date('1970-01-01T' + time);
      const roundedDatetime = roundToNearestMinutes(dateTime, {
        nearestTo: 15,
      });
      return format(roundedDatetime, 'HH:mm');
    };

    if (times.controls.length < 3) {
      this.amountOfTimeControls++;

      const control = this.fb.group(
        {
          start: [
            '',
            [Validators.required, Validators.pattern(timeValidationPattern)],
          ],
          end: [
            '',
            [Validators.required, Validators.pattern(timeValidationPattern)],
          ],
          breakStart: ['', [Validators.pattern(timeValidationPattern)]],
          breakEnd: ['', [Validators.pattern(timeValidationPattern)]],
          nextDay: false,
        },
        {
          validators: [
            (ctrl) => {
              if (
                ctrl.value.start &&
                ctrl.value.end &&
                ctrl.value.breakStart &&
                ctrl.value.breakEnd &&
                !this.timespanContained({
                  startTimeA: ctrl.value.start,
                  endTimeA: ctrl.value.end,
                  startTimeB: ctrl.value.breakStart,
                  endTimeB: ctrl.value.breakEnd,
                })
              ) {
                return {
                  breakStart: 'This control is not within range',
                  breakEnd: 'This control is not within range',
                };
              }

              return null;
            },
          ],
        }
      );

      // Prevents a user from select two of the same times
      control.valueChanges.subscribe((value) => {
        if (value.start === value.end) {
          control.get('start')?.setErrors({ sameTime: true });
          control.get('end')?.setErrors({ sameTime: true });
        } else if (value.start !== value?.end) {
          control
            .get('start')
            ?.updateValueAndValidity({ onlySelf: false, emitEvent: false });
          control
            .get('end')
            ?.updateValueAndValidity({ onlySelf: false, emitEvent: false });
        }

        // Update nextDay value if end is earlier than start
        const comparison = this.compareTimeStrings(value.start, value.end);
        if (comparison === (TimeComparison.AFTER as number)) {
          control.patchValue(
            { nextDay: true },
            { onlySelf: true, emitEvent: false }
          );
        } else if (
          comparison === (TimeComparison.BEFORE as number) ||
          comparison === (TimeComparison.EQUAL as number)
        ) {
          control.patchValue(
            { nextDay: false },
            { onlySelf: true, emitEvent: false }
          );
        }

        // If both entries are valid update total time
        if (
          timeValidationPattern.test(value.start) &&
          timeValidationPattern.test(value.end)
        ) {
          this.updateTotalMinutesInTimesForm();
        }
      });

      // All entered times must be rounded to a quarter of an hour
      control.get('start')?.valueChanges.subscribe((value) => {
        if (!timeValidationPattern.test(value)) {
          control.patchValue({ start: roundTimeStringToNearestQuarter(value) });
        }
      });

      // All entered times must be rounded to a quarter of an hour
      control.get('end')?.valueChanges.subscribe((value) => {
        if (!timeValidationPattern.test(value)) {
          control.patchValue({ end: roundTimeStringToNearestQuarter(value) });
        }
      });

      control.get('breakStart')?.valueChanges.subscribe((value) => {
        if (!timeValidationPattern.test(value)) {
          control.patchValue({
            breakStart: roundTimeStringToNearestQuarter(value),
          });
        }
      });

      control.get('breakEnd')?.valueChanges.subscribe((value) => {
        if (!timeValidationPattern.test(value)) {
          control.patchValue({
            breakEnd: roundTimeStringToNearestQuarter(value),
          });
        }
      });

      if (start !== '' && end !== '') {
        control.patchValue({ start, end });
      }

      if (breakStart !== '' && breakEnd !== '') {
        control.patchValue({ breakStart, breakEnd });
      }

      times.push(control);
    }
  }

  timeToDate = (time: string): Date => new Date('1970-01-01T' + time);

  calculateTotal(params: {
    startTime: string;
    endTime: string;
    breakStart: string;
    breakEnd: string;
  }) {
    let [startTimeA, endTimeA, startTimeB, endTimeB] = [
      ...Object.values(params),
    ].map((value) =>
      value ? this.timeToDate(value) : this.timeToDate('00:00')
    );

    if (compareAsc(startTimeA, endTimeA) === TimeComparison.AFTER) {
      endTimeA = addDays(endTimeA, 1);
    }

    if (params.breakStart && params.breakEnd) {
      if (compareAsc(startTimeB, endTimeB) === TimeComparison.AFTER) {
        endTimeB = addDays(endTimeB, 1);
      }

      return Math.abs(
        (differenceInMinutes(startTimeA, endTimeA) -
          differenceInMinutes(startTimeB, endTimeB)) /
          60
      ).toFixed(2);
    }

    return Math.abs(differenceInMinutes(startTimeA, endTimeA) / 60).toFixed(2);
  }

  differenceInMinutes(start: string, end: string, daysBetween = 0): number {
    return Math.abs(
      differenceInMinutes(
        this.timeToDate(start),
        addDays(this.timeToDate(end), daysBetween)
      )
    );
  }

  calculateTotalHoursInTimesForm(): number {
    const timesArray = this.timesForm
      .get('days')
      ?.value.map((value: any) => value.times);

    let total = 0;
    timesArray.forEach((value: any) => {
      value.forEach((time: any) => {
        const { start, end, breakStart, breakEnd } = time;
        total += parseFloat(
          this.calculateTotal({
            startTime: start,
            endTime: end,
            breakStart,
            breakEnd,
          })
        );
      });
    });

    return total;
  }

  /**
   * Calculates the total amount of minutes registered
   */
  updateTotalMinutesInTimesForm(): void {
    const total = this.calculateTotalHoursInTimesForm();
    this.totalMinutesInTimesForm.next(total * 60);
    this.totalHoursInTimesForm.next(total.toFixed(2));
  }

  timespanContained(params: {
    startTimeA: string;
    endTimeA: string;
    startTimeB: string;
    endTimeB: string;
  }) {
    let [startTimeA, endTimeA, startTimeB, endTimeB] = [
      ...Object.values(params),
    ].map((value) => this.timeToDate(value));

    if (compareAsc(startTimeA, endTimeA) === TimeComparison.AFTER) {
      endTimeA = addDays(endTimeA, 1);
    }

    if (compareAsc(startTimeB, endTimeB) === TimeComparison.AFTER) {
      endTimeB = addDays(endTimeB, 1);
    }

    // If endTimeA is next day and startTimeB is smaller than startTimeA, add a day to startTimeB and endTimeB
    if (endTimeA > startTimeA && startTimeB < startTimeA) {
      startTimeB = addDays(startTimeB, 1);
      endTimeB = addDays(endTimeB, 1);
    }

    return (
      isWithinInterval(startTimeB, { start: startTimeA, end: endTimeA }) &&
      isWithinInterval(endTimeB, { start: startTimeA, end: endTimeA })
    );
  }

  /**
   * Checks whether two time strings overlap
   */
  areTimeStringsInSameRange(
    startOne: string,
    endOne: string,
    startTwo: string,
    endTwo: string
  ): boolean {
    const dt0 = this.timeToDate(startOne);
    let dt1 = this.timeToDate(endOne);
    // If dt1 comes earlier than dt0 it's next day
    if (compareAsc(dt0, dt1) === TimeComparison.AFTER) {
      dt1 = addDays(dt1, 1);
    }

    const dt2 = this.timeToDate(startTwo);
    let dt3 = this.timeToDate(endTwo);
    // If dt2 comes earlier than dt3 it's next day
    if (compareAsc(dt2, dt3) === TimeComparison.AFTER) {
      dt3 = addDays(dt3, 1);
    }

    if (isValid(dt0) && isValid(dt1) && isValid(dt2) && isValid(dt3)) {
      return areIntervalsOverlapping(
        { start: dt0, end: dt1 },
        { start: dt2, end: dt3 }
      );
    }

    return false;
  }

  /**
   * Add a new time control on the form
   */
  createDateControl(date: Date): FormGroup {
    const control = this.fb.group({
      date,
      times: this.fb.array([]),
    });

    control.controls.times.valueChanges.subscribe((ctrl) => {
      // Check (locally within controlArray) for overlapping dates
      for (let i = 0; i < ctrl.length; i++) {
        if (ctrl[i].start !== '' && ctrl[i].end !== '') {
          for (let z = i + 1; z < ctrl.length; z++) {
            // Double loop through controls to find overlaps
            if (
              this.areTimeStringsInSameRange(
                ctrl[i].start,
                ctrl[i].end,
                ctrl[z].start,
                ctrl[z].end
              )
            ) {
              control.get('times')?.setErrors({ overlapping: true });
            }
          }
        }
      }
    });

    return control;
  }

  /**
   * Update the timesheet in memory
   */
  updateWorkingTimesheet(): void {
    this.workingTimesheet.id = this.isEditing
      ? this.editingTimesheet.id
      : undefined;
    this.workingTimesheet.client_id = this.isEditing
      ? this.editingTimesheet.client.id
      : this.generalInfoForm.value.client.id;
    this.workingTimesheet.department_id = this.isEditing
      ? this.editingTimesheet.department.id
      : this.generalInfoForm.value.department.id;
    this.workingTimesheet.shift_id = this.isEditing
      ? this.editingTimesheet.shift.id
      : this.generalInfoForm.value.shift.id;
    // Possible workingTimesheet might not have a year
    this.workingTimesheet.year = this.isEditing
      ? this.workingTimesheet.year
      : +`${formatDate(
          this.generalInfoForm.value.weekYear,
          'yyyy',
          this.locale
        )}`;
    this.workingTimesheet.week = this.isEditing
      ? this.workingTimesheet.week
      : +`${formatDate(this.generalInfoForm.value.weekYear, 'w', this.locale)}`;
    this.workingTimesheet.starts = new Array<string>();
    this.workingTimesheet.ends = new Array<string>();
    this.workingTimesheet.break_starts = new Array<string>();
    this.workingTimesheet.break_ends = new Array<string>();
    this.workingTimesheet.days = new Array<number>();

    for (const [key, day] of this.timesForm.get('days')?.value.entries()) {
      day.times.forEach((time: any) => {
        const { start, end, breakStart, breakEnd, nextDay } = time;
        const [startH, startM] = start.split(':').map(Number);
        const [endH, endM] = end.split(':').map(Number);

        const startDate = new Date(day.date);
        startDate.setHours(startH);
        startDate.setMinutes(startM);

        const endDate = new Date(day.date);
        endDate.setHours(endH);
        endDate.setMinutes(endM);
        if (nextDay) {
          endDate.setDate(endDate.getDate() + 1);
        }

        this.workingTimesheet.days.push(startDate.getUTCDay());
        this.workingTimesheet.starts.push(
          startDate.toISOString().slice(11, 16)
        );
        this.workingTimesheet.ends.push(endDate.toISOString().slice(11, 16));

        if (
          breakStart &&
          breakEnd &&
          breakStart.match(/^[0-9]{2}:[0-9]{2}$/) &&
          breakEnd.match(/^[0-9]{2}:[0-9]{2}$/)
        ) {
          const [breakStartH, breakStartM] = breakStart
            .split(':')
            .map(Number);
          const [breakEndH, breakEndM] = breakEnd.split(':').map(Number);

          const breakStartDate = new Date(day.date);
          breakStartDate.setHours(breakStartH); 
          breakStartDate.setMinutes(breakStartM);

          const breakEndDate = new Date(day.date);
          breakEndDate.setHours(breakEndH);
          breakEndDate.setMinutes(breakEndM);

          if (breakEndDate < breakStartDate) {
            breakEndDate.setDate(breakEndDate.getDate() + 1);
          }

          this.workingTimesheet.break_starts.push(breakStartDate.toISOString().slice(11, 16));
          this.workingTimesheet.break_ends.push(breakEndDate.toISOString().slice(11, 16));
        }
      });
    }
  }

  /**
   * Actions to execute when a timesheet is submitted
   * Submits timesheet in memory
   */
  async onSubmit(): Promise<void> {
    this.signatureClientCanvas.nativeElement.style.border =
      this.signatureUserCanvas.nativeElement.style.border = '';

    if (this.signaturePadUser.isEmpty()) {
      this.notificationService.notifyInfo('MESSAGES.MISSING_USER_SIGNATURE');
      this.signatureUserCanvas.nativeElement.style.border = '1px solid red';
      return;
    } else if (this.signaturePadClient.isEmpty()) {
      this.notificationService.notifyInfo('MESSAGES.MISSING_CLIENT_SIGNATURE');
      this.signatureClientCanvas.nativeElement.style.border = '1px solid red';
      return;
    }

    this.isSubmitting = true;
    this.updateWorkingTimesheet();
    const result = this.workingTimesheet;

    try {
      const { timesheet_id } = await this.timesheetRepository
        .saveTimesheet(result)
        .toPromise();

      if (timesheet_id) {
        await this.timesheetRepository
          .signTimesheet(
            timesheet_id,
            SigType.USER,
            this.signaturePadUser.toDataURL(),
            ''
          )
          .toPromise();

        await this.timesheetRepository
          .signTimesheet(
            timesheet_id,
            SigType.CLIENT,
            this.signaturePadClient.toDataURL(),
            this.clientValidationForm.get('clientName')?.value
          )
          .toPromise();

        this.notificationService.saveSuccess();
        await this.router.navigate(['/overview']);
      }
    } catch (error) {
      // @ts-ignore-next-line
      this.notificationService.saveError(error?.status ?? 'An error occurred');
    } finally {
      this.isSubmitting = false;
    }
  }
}
