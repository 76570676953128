<h2 mat-dialog-title>{{submittedTimesheet.client.name}}</h2>
<mat-dialog-content class="mat-typography">
  <mat-expansion-panel style="box-shadow: none" expanded="true">
    <mat-expansion-panel-header>
      {{'OVERVIEW.GENERALINFO' | translate | titlecase}}
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <table id="generalInfoTable">
        <tr>
          <td><b>{{'OVERVIEW.WEEK' | translate | titlecase}}</b></td>
          <td>{{submittedTimesheet.hours[0].start | date: 'w (MMMM)' }}</td>
        </tr>
        <tr>
          <td><b>{{'OVERVIEW.DEPARTMENT' | translate | titlecase}}</b></td>
          <td>{{submittedTimesheet.department.name}}</td>
        </tr>
        <tr>
          <td><b>{{'OVERVIEW.SHIFT' | translate | titlecase}}</b></td>
          <td>{{submittedTimesheet.shift.name}}</td>
        </tr>
      </table>
    </ng-template>
  </mat-expansion-panel>
  <mat-expansion-panel style="box-shadow: none" expanded="true">
    <mat-expansion-panel-header>
      {{'OVERVIEW.TIMEREGISTRATIONS' | translate | titlecase}}
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <table id="hoursTable">
        <th colspan="2">{{'OVERVIEW.START' | translate | titlecase}}</th>
        <th colspan="2">{{'OVERVIEW.END' | translate | titlecase}}</th>
        <th colspan="1">{{'OVERVIEW.BREAK' | translate | titlecase}}</th>
        <th>{{'OVERVIEW.HOUR' | translate | titlecase}}</th>

        <ng-container *ngFor=" let row of submittedTimesheet.hours ">
          <tr>
            <td>{{row.start | date: 'E' | titlecase}}</td>
            <td>{{row.start | date: 'HH:mm' | titlecase}}</td>
            <td>{{row.end | date: 'E' | titlecase }}</td>
            <td>{{row.end | date: 'HH:mm' | titlecase }}</td>
            <td *ngIf="row.break_start && row.break_end; else noBreaks">
              {{row.break_start | date: 'HH:mm' | titlecase }}
              -
              {{row.break_end | date: 'HH:mm' | titlecase }}
            </td>
            <ng-template #noBreaks>
              -
            </ng-template>
            <td>{{row.hours}}</td>
          </tr>
        </ng-container>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td style="border-top: 1px solid gray; padding-top: 8px;"> <b>{{'OVERVIEW.TOTAL' | translate | titlecase}}</b><br/> {{totalHours}}</td>
        </tr>
      </table>
    </ng-template>
  </mat-expansion-panel>
  <mat-expansion-panel style="box-shadow: none">
    <mat-expansion-panel-header>
      Handtekeningen
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <span>{{ 'OVERVIEW.USERSIGNATURE' | translate  }}</span>
      <img width="100%" style="border: 1px dashed" [src]="'data:image/png;base64,' + userSignature">
      <span>{{ 'OVERVIEW.CLIENTSIGNATURE' | translate }}</span>
      <img width="100%" style="border: 1px dashed" [src]="'data:image/png;base64,' + clientSignature">
    </ng-template>
  </mat-expansion-panel>
</mat-dialog-content>
<mat-dialog-actions style="display: flex; justify-content: space-between;">
  <button mat-button mat-dialog-close cdkFocusInitial>{{'OVERVIEW.EXIT' | translate | titlecase}}</button>
  <button mat-button (click)="editSelectedData()">{{'OVERVIEW.EDIT' | translate | titlecase}}</button>
</mat-dialog-actions>
