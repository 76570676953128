import {NgModule} from '@angular/core';

// App
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OverviewDialogComponent } from './_components/overview/overview-dialog.component';

// Translation
import {CoreModule} from '@app/core/core.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CoreModule,
    AppRoutingModule,
  ],
  entryComponents: [
    OverviewDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
