import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ISubmittedTimesheet} from '@app/_models';
import {addMonths} from 'date-fns';
import {MatDialog} from '@angular/material/dialog';
import {OverviewDialogComponent} from './overview-dialog.component';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {TimesheetRepositoryService, TranslationService} from '@app/_services';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css'],
})
export class OverviewComponent implements AfterViewInit {
  // Defines the columns to display and the order in which they're displayed
  columnsToDisplay: string[] = ['week', 'client', 'status', 'hours'];
  columnsToDisplayDesktop: string[] = ['week', 'client', 'department', 'shift', 'status', 'hours'];
  @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource(new Array<ISubmittedTimesheet>());
  currentDate = new BehaviorSubject<Date>(new Date());

  private statuses = {WAITING: '', PROCESSING: '', SENT: '', REJECTED: '', COMPLETED: '', CANCELLED: '', RESUBMITTED: ''};

  constructor(public dialog: MatDialog,
              private translationService: TranslationService,
              private timesheetService: TimesheetRepositoryService)
  {
    translationService.getTranslation('STATUS').subscribe((val: any) => this.statuses = val);
  }

  /**
   * On date change refresh timesheets in repository
   * On timesheets fetched, set datasource
   */
  ngOnInit(): void {
    this.currentDate.subscribe(value => {
      // Months are n = [0..11], API expects n+1
      this.timesheetService.refreshSubmittedTimesheets(value.getFullYear(), value.getMonth() + 1);
    });

    this.timesheetService.submittedTimesheets.subscribe(timesheets => {
      this.dataSource.data = timesheets;
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  /**
   * Returns whether the user's on a small screen or not
   */
  get smallScreen(): boolean {
    return screen.width <= 800;
  }

  /**
   * Opens a dialog in this component
   */
  public openDialog(data: ISubmittedTimesheet): void {
    this.dialog.open(OverviewDialogComponent, {
      data,
      width: '95vw',
      maxWidth: 600,
      maxHeight: 800,
      minHeight: 200
    });
  }

  /**
   * Tries to provide a translation for a status retrieved from the api or returns a status
   */
  translateStatus(status: string): string {
    if (/afwachting/.test(status)) {
      return this.statuses.WAITING;
    } else if (/behandeling/i.test(status)) {
      return this.statuses.PROCESSING;
    } else if (/verstuurd/i.test(status)) {
      return this.statuses.SENT;
    } else if (/afgerond/i.test(status)) {
      return this.statuses.COMPLETED;
    } else if (/afgekeurd/i.test(status)) {
      return this.statuses.REJECTED;
    } else if (/afgebroken/i.test(status)) {
      return this.statuses.CANCELLED;
    } else if (/opnieuw/i.test(status)) {
      return this.statuses.RESUBMITTED;
    }
    return status;
  }

  /**
   * Calculates the total hours from a given timesheet
   */
  reduceHoursToTotal(submittedTimesheet: ISubmittedTimesheet): number {
    let result = 0;
    submittedTimesheet?.hours.forEach(val => {
      result += val?.hours;
    });
    return result;
  }

  /**
   * Updates the overview by adding or subtracting a month
   */
  addMonthToViewingDate(amount: number): void {
    this.currentDate.next(addMonths(this.currentDate.getValue(), amount));
  }

  /**
   * Resets the overview to the current date
   */
  resetViewingDate(): void {
    this.currentDate.next(new Date());
  }
}
