<div class="app-navbar-notch">
  <span></span>
</div>
<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span mat-button style="margin-right: 30px">Dujob</span>
    <button mat-button (click)="onOverviewClick()" *ngIf="userAuthenticated && !smallScreen && !isChangingPassword">{{'TOOLBAR.OVERVIEW' | translate | titlecase}}</button>
    <button mat-button (click)="onCreateClick()" *ngIf="userAuthenticated && !smallScreen && !isChangingPassword">{{'TOOLBAR.REGISTRATION' | translate | titlecase}}</button>
    <div class="spacer"></div>
    <div style="background: black; color: white;" *ngIf="dataIsBeingMocked">Data mocking enabled</div>
    <button mat-button [matMenuTriggerFor]="menu">{{currentLang | async | uppercase}}<mat-icon>expand_more</mat-icon></button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngFor="let lang of availableLangs" (click)="setLanguage(lang)">{{lang | uppercase}}</button>
    </mat-menu>
    <button mat-button (click)="onLogoffClick()" *ngIf="userAuthenticated && !smallScreen">{{'TOOLBAR.LOGOUT' | translate }}</button>
  </mat-toolbar-row>

</mat-toolbar>
<mat-toolbar class="toolbarNav" *ngIf="userAuthenticated && smallScreen && !isChangingPassword">
  <button mat-icon-button (click)="onOverviewClick()">
    <mat-icon class="material-icons color_blue">
      view_headline
    </mat-icon>
  </button>
  <button mat-icon-button (click)="onCreateClick()">
    <mat-icon class="material-icons color_blue">
      add
    </mat-icon>
  </button>
  <button mat-icon-button (click)="onLogoffClick()" *ngIf="userAuthenticated">
    <mat-icon class="material-icons color_blue">
      exit_to_app
    </mat-icon>
  </button>
</mat-toolbar>
<router-outlet></router-outlet>
