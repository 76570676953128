import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';

/**
 * JSON Web Token Class
 */
export class JWT {
  constructor() {
    this.token_type = '';
    this.expires_in = 0;
    this.access_token = '';
    this.refresh_token = '';
  }

  // tslint:disable:variable-name
  public token_type: string;
  public expires_in: number;
  public access_token: string;
  public refresh_token: string;
}

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private userToken: JWT | null;
  public userProfile: any;

  constructor(private httpClient: HttpClient) {
    this.userToken = this.retrieveToken('jwt');
  }

  /**
   * Whether the user profile has loaded already
   */
  userProfileLoaded(): boolean {
    return this.userProfile !== null;
  }

  /**
   * Fetch a user profile from the API
   */
  fetchUserProfile(): any {
    if (this.isAuthenticated) {
      return this.getUserProfile().subscribe(result => {
        return this.userProfile = result;
      });
    }

    return this.userProfile;
  }

  /**
   * Return whether the user is authenticated by checking if the userToken !== null
   */
  get isAuthenticated(): boolean {
    return this.userToken != null;
  }

  /**
   * Return the current access token for logging
   */
  get getLoggingAccessToken(): string | undefined {
    return 'placeholder';
  }

  /**
   * Return the current user's access token
   */
  get getUserAccessToken(): string | undefined {
    return this.userToken?.access_token;
  }

  /**
   * API Call to change the current user's password with a new password
   */
  changePassword(password: string, password_confirmation: string): Observable<any> {
    return this.httpClient.post(`${environment.API_URL}/user/password/change`, {password, password_confirmation});
  }

  /**
   * Fetch a new authentication token from the OAUTH server
   */
  fetchToken(body: {email: string, password: string}): Observable<JWT> {
    return this.httpClient.post<{status: string; data: {token: string}}>(`${environment.API_URL}/token`, {...body, device_name: "DUJOB_APP"}).pipe(map(result => {
      const jwt = new JWT();
      jwt.access_token = result.data.token;
      return jwt;
    }));
  }

  /**
   * Get the current user's profile
   */
  getUserProfile(): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/user/profile`);
  }

  /**
   * Request a password reset for a user using their e-mail
   */
  requestPasswordReset(email: string): Observable<any> {
    return this.httpClient.post(`${environment.API_URL}/password/forgot`, {email});
  }

  /**
   * Login a user using name and password
   */
  login(username: string, password: string): Observable<any> {
    return this.fetchToken({email: username, password}).pipe(map(result => {
      this.setToken('jwt', result);
      this.userToken = this.retrieveToken('jwt');
    }));
  }

  /**
   * Logout the current user
   */
  logout(): void {
    this.userToken = null;
    this.setToken('jwt', null);
  }


  /**
   * Set a token in localstorage
   * TODO: API should send token and refresh token in HttpOnly cookie every request!
   * The refresh token should not have to be stored in localstorage!
   */
  setToken(key: string, token: JWT | null): void {
    localStorage.setItem(key, JSON.stringify(token));
  }

  /**
   * Retrieve a token using a specific localstorage key
   */
  retrieveToken(key: string): JWT | null {
    const str = localStorage.getItem(key);
    return str == null ? str : JSON.parse(str) as JWT;
  }
}
