import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatProgressBar} from '@angular/material/progress-bar';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { passwordEqualityValidator } from '@app/_helpers/Validators/CustomValidators';
import {AuthenticationService, InAppNotificationService} from '@app/_services';
import * as zxcvbn from 'zxcvbn';
import {Router} from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements AfterViewInit {
  passwordResetMinimumLength = 8;
  progressBarValue = 0;
  passwordStrengthStringValue = '';
  passwordStrengthScore = -1;

  @ViewChild('passwordStrengthBar') passwordStrengthBar: MatProgressBar;
  public passwordForm: FormGroup;

  constructor(private fb: FormBuilder,
              private auth: AuthenticationService,
              private router: Router,
              private notify: InAppNotificationService) {
    const passwordPattern = /^[0-9a-zA-Z!@\#\$%\^&\*\(\)\-\=\_\+]+$/;

    this.passwordForm = fb.group({
      password: ['', [
        Validators.required,
        Validators.minLength(this.passwordResetMinimumLength),
        Validators.pattern(passwordPattern)]
      ],
      passwordConfirmation: ['', [Validators.required]]
    }, {validators: passwordEqualityValidator});
  }

  ngAfterViewInit(): void {
    this.passwordForm.get('password')?.valueChanges.subscribe(value => {
      const passwordStrengthScore = zxcvbn(value).score;
      this.passwordStrengthScore = passwordStrengthScore;
      this.progressBarValue = this.measurePasswordStrengthScore(passwordStrengthScore);
      this.passwordStrengthStringValue = this.passwordStrengthString(passwordStrengthScore);
    });
  }

  /**
   * Measure a given password's strength
   */
  measurePasswordStrengthScore(score: number): number
  {
    switch (score){
      case 0:
        return 0;
      case 1:
        return 25;
      case 2:
        return 50;
      case 3:
        return 75;
      case 4:
        return 100;
      default:
        return 0;
    }
  }

  /**
   * Supply a text for a given score
   */
  passwordStrengthString(score: number): string {
    switch (score) {
      case 0:
        return 'Niet sterk';
      case 1:
        return 'Matig';
      case 2:
        return 'Redelijk';
      case 3:
        return 'Sterk';
      case 4:
        return 'Zeer sterk';
      default:
        return '';
    }
  }

  /**
   * Actions that are executed when the password is submitted through the form
   */
  onSubmit(): void {
    const password = this.passwordForm.get('password')?.value;
    const passwordConfirmation = this.passwordForm.get('passwordConfirmation')?.value;

    if (password !== null && password === passwordConfirmation) {
      this.auth.changePassword(password, passwordConfirmation).subscribe(() => {
        this.auth.logout();
        this.router.navigate(['/login']);
      }, (error) => {
        this.notify.submitFormError();
      });
    }
  }

  /**
   * Actions executed when a user cancels changing their password
   * Logout; go to login.
   */
  onCancel(): void {
    this.auth.logout();
    this.router.navigate(['/login']);
  }
}
