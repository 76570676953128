import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './_components/login/login.component';
import { OverviewComponent } from './_components/overview/overview.component';
import { AuthGuard } from './_helpers';
import {DeclarationComponent} from '@app/_components/declaration/declaration.component';
import { ChangePasswordComponent } from './_components/change-password/change-password.component';

/**
 * Global application routes
 */
const routes: Routes = [
  { path: 'login', component: LoginComponent, data: {name: 'Inloggen'} },
  { path: 'timesheets/overview', component: OverviewComponent, canActivate: [AuthGuard]},
  { path: 'timesheets/new', component: DeclarationComponent, canActivate: [AuthGuard]},
  { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard]},

  // Redirect all other paths to overview
  { path: '**', redirectTo: 'timesheets/overview'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
